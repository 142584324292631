import React, { useState, useRef, useEffect } from "react";
import firebase from "firebase/compat/app";
import { Controller, useForm } from "react-hook-form";
import {
  Backdrop,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormHelperText,
  TextField,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";

const AccountSettings = ({ user, visible, close, setSubmitted }) => {
  const {
    control,
    handleSubmit,
    formState: { errors },
    watch,
    reset,
  } = useForm();
  const [loading, setLoading] = useState(false);
  const [firebaseError, setFirebaseError] = useState();
  const tempPasswordRef = useRef(null);

  // Watch form values to determine what's being updated
  const watchEmail = watch("email");
  const watchNewPassword = watch("newPassword");

  const onSubmit = async (data) => {
    setLoading(true);
    setFirebaseError(null);
    setSubmitted(false);

    // Store password temporarily in memory only
    tempPasswordRef.current = data.currentPassword;

    try {
      console.log("Current user email:", user.email); // Debug log

      // Only proceed if we're actually making changes
      if (data.email === user.email && !data.newPassword) {
        setFirebaseError("No changes detected");
        setLoading(false);
        return;
      }

      // Create credential with current password
      try {
        const credential = firebase.auth.EmailAuthProvider.credential(
          user.email,
          data.currentPassword
        );

        await user.reauthenticateWithCredential(credential);

        // Track if we need to show multiple success messages
        let messages = [];

        // Handle email change
        if (data.email && data.email !== user.email) {
          try {
            await user.verifyBeforeUpdateEmail(data.email);
            messages.push(
              "A verification email has been sent to your new email address. " +
                "Please check your inbox and click the verification link to complete the email change."
            );
          } catch (emailError) {
            if (emailError.code === "auth/requires-recent-login") {
              throw emailError; // Pass this to outer catch block
            }
            console.error("Email update error:", emailError);
            throw new Error(
              "Failed to send verification email. Please try again later."
            );
          }
        }

        // Handle password change
        if (data.newPassword) {
          await user.updatePassword(data.newPassword);
          messages.push("Your password has been updated successfully.");
        }

        // Reset form and show success message(s)
        reset({
          currentPassword: "",
          email: user.email,
          newPassword: "",
        });
        setSubmitted(messages.join(" "));
        close();
        return;
      } catch (authError) {
        console.error("Authentication error:", authError);
        switch (authError.code) {
          case "auth/invalid-credential":
          case "auth/wrong-password":
            setFirebaseError("The current password you entered is incorrect.");
            break;
          case "auth/requires-recent-login":
            setFirebaseError(
              "For security reasons, please sign out and sign in again before making this change."
            );
            break;
          case "auth/email-already-in-use":
            setFirebaseError(
              "This email is already in use by another account."
            );
            break;
          case "auth/invalid-email":
            setFirebaseError("The email address is not valid.");
            break;
          case "auth/operation-not-allowed":
            setFirebaseError(
              "A verification email has been sent to your new email address. " +
                "Please check your inbox and click the verification link to complete the email change."
            );
            reset({
              currentPassword: "",
              email: user.email, // Keep the current email until verified
            });
            close();
            break;
          default:
            setFirebaseError(`An error occurred: ${authError.message}`);
        }
      }
    } catch (error) {
      console.error("Unexpected error:", error);
      setFirebaseError(`An unexpected error occurred: ${error.message}`);
    } finally {
      setLoading(false);
      // Clear the temporary password
      tempPasswordRef.current = null;
    }
  };

  // Clear temp password on unmount
  useEffect(() => {
    return () => {
      tempPasswordRef.current = null;
    };
  }, []);

  // When dialog closes, reset the form
  const handleClose = () => {
    reset({
      email: user.email,
      newPassword: "",
      currentPassword: "",
    });
    close();
  };

  // If user data not loaded yet, return loading screen
  if (!user) {
    return (
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={visible}
        onClick={close}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    );
  }

  return (
    <Dialog open={visible} onClose={handleClose}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <DialogTitle>Update Your Account</DialogTitle>
        <DialogContent>
          <DialogContentText sx={{ mb: 3 }}>
            Update your email address or password below. You'll need to enter
            your current password to make any changes.
          </DialogContentText>

          <Controller
            name="email"
            control={control}
            defaultValue={user?.email || ""}
            rules={{
              pattern: {
                value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                message: "Invalid email address.",
              },
            }}
            render={({ field }) => (
              <TextField
                id="email"
                label="Email"
                type="email"
                InputLabelProps={{ shrink: true }}
                variant="standard"
                fullWidth
                error={!!errors?.email}
                helperText={errors?.email?.message}
                sx={{ mb: 2 }}
                {...field}
              />
            )}
          />

          <Controller
            name="newPassword"
            control={control}
            defaultValue=""
            rules={{
              minLength: {
                value: 8,
                message: "Password must be at least 8 characters long.",
              },
              pattern: {
                value:
                  /(?=^.{8,}$)((?=.*\d)|(?=.*\W+))(?![.\n])(?=.*[A-Z])(?=.*[a-z]).*$/,
                message:
                  "Password must contain uppercase and lowercase letters, and at least one number or special character.",
              },
            }}
            render={({ field }) => (
              <TextField
                id="newPassword"
                label="New Password (optional)"
                type="password"
                InputLabelProps={{ shrink: true }}
                variant="standard"
                fullWidth
                error={!!errors?.newPassword}
                helperText={errors?.newPassword?.message}
                sx={{ mb: 2 }}
                {...field}
              />
            )}
          />

          <Controller
            name="currentPassword"
            control={control}
            defaultValue=""
            rules={{
              required: {
                value: !!(watchEmail !== user?.email || watchNewPassword),
                message: "Current password is required to make changes.",
              },
            }}
            render={({ field }) => (
              <TextField
                id="currentPassword"
                label="Current Password"
                type="password"
                InputLabelProps={{ shrink: true }}
                variant="standard"
                fullWidth
                error={!!errors?.currentPassword}
                helperText={errors?.currentPassword?.message}
                {...field}
              />
            )}
          />

          {firebaseError && (
            <FormHelperText error sx={{ mt: 1 }}>
              {firebaseError}
            </FormHelperText>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Close</Button>
          <LoadingButton
            loading={loading}
            type="submit"
            disabled={
              loading || (!watchNewPassword && watchEmail === user?.email)
            }
            autoFocus
            variant="contained"
          >
            Update Account
          </LoadingButton>
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default AccountSettings;
