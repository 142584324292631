import React, { useContext, useEffect, useRef, useState } from "react";
import "@chatscope/chat-ui-kit-styles/dist/default/styles.min.css";
import {
  MessageList,
  Message,
  TypingIndicator,
  ChatContainer,
} from "@chatscope/chat-ui-kit-react";
import { Box, CircularProgress, useTheme, useMediaQuery } from "@mui/material";
import { ChatContext } from "context/Chat";
import { useSpeechRecognition } from "react-speech-recognition";
import { useMessageParser } from "components/chatbot/Chat/useMessageParser";
import { useMessageHandler } from "components/chatbot/Chat/useMessageHandler";

// Import components
import Options from "components/chatbot/Options";
import Disclaimer from "components/chatbot/Chat/Disclaimer";
import ChatWrapper from "components/chatbot/Chat/ChatWrapper";
import CustomMessageInput from "components/chatbot/Chat/CustomMessageInput";
import ProfessionalDisclaimer from "./Chat/ProfessionalDisclaimer";
import AdminTestBar from "./AdminTestBar";

const Chatbot = ({
  visible = true,
  user,
  userData,
  expanded,
  openTab,
  tabs,
}) => {
  const {
    messages = [],
    rateLimited,
    limit,
    isMessagesLoading,
  } = useContext(ChatContext);
  const chatBoxRef = useRef(null);

  const {
    transcript,
    finalTranscript,
    listening,
    browserSupportsSpeechRecognition,
  } = useSpeechRecognition();

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const [inputValue, setInputValue] = useState("");
  const [computedValue, setComputedValue] = useState("");
  const [isTyping, setIsTyping] = useState(false);
  const [submitInfo, setSubmitInfo] = useState(true);
  const [selectedAssistant, setSelectedAssistant] = useState("");
  const { parseMessage } = useMessageParser(openTab);

  const { handleSendRequest } = useMessageHandler({
    user,
    userData,
    setIsTyping,
    submitInfo,
    assistantId: selectedAssistant,
  });

  // Auto-scroll effect
  useEffect(() => {
    if (chatBoxRef.current) {
      chatBoxRef.current.scrollTop = chatBoxRef.current.scrollHeight;
    }
  }, [messages, isTyping]); // Re-run when messages change or typing status changes

  // Handle speech recognition
  useEffect(() => {
    if (transcript && listening) {
      setComputedValue(inputValue + " " + transcript);
    }

    if (finalTranscript) {
      setInputValue((prev) => prev + " " + finalTranscript);
      setComputedValue((prev) => prev + " " + finalTranscript);
    }
  }, [transcript, finalTranscript, listening, inputValue]);

  const updateComputed = () => {
    setComputedValue(inputValue);
  };

  const onSendMessage = async (message) => {
    try {
      setInputValue("");
      await handleSendRequest(message);
    } catch (error) {
      console.error("Error sending message:", error);
    }
  };

  if (isMessagesLoading) {
    return (
      <Box
        sx={{
          height: "100dvh",
          width: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <CircularProgress />
      </Box>
    );
  }

  if (!messages || messages.length === 0)
    return (
      <>
        <ProfessionalDisclaimer userData={userData} />
        <Box
          sx={{
            height: "100dvh",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
          }}
        >
          <Options
            sendMessage={handleSendRequest}
            user={user}
            userData={userData}
            submitInfo={submitInfo}
            setSubmitInfo={setSubmitInfo}
          />

          <ChatContainer
            style={{ height: 100, flexBasis: 0, flexGrow: 0, order: "initial" }}
          >
            <div as="MessageInput" style={{ height: 50 }}>
              <CustomMessageInput
                inputValue={inputValue}
                setInputValue={setInputValue}
                computedValue={computedValue}
                handleSendRequest={onSendMessage}
                rateLimited={rateLimited}
                limit={limit}
                listening={listening}
                browserSupportsSpeechRecognition={
                  browserSupportsSpeechRecognition
                }
                updateComputed={updateComputed}
              />
            </div>
          </ChatContainer>

          <Disclaimer userData={userData} />
        </Box>
        {userData?.admin && (
          <AdminTestBar
            onAssistantChange={(value) => setSelectedAssistant(value)}
          />
        )}
      </>
    );

  return (
    <>
      <ProfessionalDisclaimer userData={userData} />
      <Box
        sx={{
          display: visible ? "block" : "none",
          width: "100%",
          overflow: "scroll",
        }}
        ref={chatBoxRef}
      >
        <Box>
          <ChatWrapper expanded={expanded} full={!tabs || tabs.length === 0}>
            <MessageList
              typingIndicator={
                isTyping ? (
                  <TypingIndicator content="HLTHDSK is typing..." />
                ) : null
              }
            >
              {messages?.map((message, i) => {
                const parsedText = parseMessage(message);

                return (
                  <Message
                    key={`message_${i}`}
                    model={{
                      direction: message.direction,
                      position: "normal",
                    }}
                  >
                    <Message.CustomContent>{parsedText}</Message.CustomContent>
                  </Message>
                );
              })}
            </MessageList>

            <div
              as="MessageInput"
              style={{
                position: "absolute",
                bottom: 0,
                left: 0,
                right: 0,
                margin: "0 auto",
                paddingTop: 10,
                paddingBottom: 10,
                backgroundColor: "#fff",
              }}
            >
              <CustomMessageInput
                inputValue={inputValue}
                setInputValue={setInputValue}
                computedValue={computedValue}
                handleSendRequest={onSendMessage}
                rateLimited={rateLimited}
                limit={limit}
                listening={listening}
                browserSupportsSpeechRecognition={
                  browserSupportsSpeechRecognition
                }
                updateComputed={updateComputed}
              />
            </div>
          </ChatWrapper>
        </Box>
      </Box>

      {userData?.admin && (
        <AdminTestBar
          onAssistantChange={(value) => setSelectedAssistant(value)}
        />
      )}
    </>
  );
};

export default Chatbot;
