import React, {
  useState,
  useContext,
  useEffect,
  useMemo,
  useCallback,
} from "react";
import firebase from "firebase/compat/app";
import "firebase/compat/functions";
import firebaseApp, { db } from "services/firebase";
import { AuthContext } from "context/Auth";
import {
  Alert,
  Box,
  Button,
  Checkbox,
  CircularProgress,
  FormHelperText,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { useNavigate } from "react-router-dom";

const ApprovalTable = ({ rows, selected, onSelectAll, onSelectOne }) => (
  <TableContainer component={Paper} sx={{ mb: 4 }}>
    <Table sx={{ minWidth: 650 }} aria-label="approval table">
      <TableHead>
        <TableRow>
          <TableCell padding="checkbox">
            <Checkbox
              color="primary"
              indeterminate={
                selected.length > 0 && selected.length < rows.length
              }
              checked={rows.length > 0 && selected.length === rows.length}
              onChange={onSelectAll}
              inputProps={{ "aria-label": "select all locations" }}
            />
          </TableCell>
          <TableCell>Clinic Name</TableCell>
          <TableCell>Address</TableCell>
          <TableCell>City</TableCell>
          <TableCell>State</TableCell>
          <TableCell>ZIP</TableCell>
          <TableCell>Map</TableCell>
          <TableCell>Email</TableCell>
          <TableCell>Registration Date</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {rows.map((row) => (
          <TableRow
            key={row.id}
            sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
          >
            <TableCell padding="checkbox">
              <Checkbox
                color="primary"
                checked={selected.includes(row.id)}
                onClick={(event) => onSelectOne(event, row.id)}
              />
            </TableCell>
            <TableCell>{row.title}</TableCell>
            <TableCell>{row.address}</TableCell>
            <TableCell>{row.city}</TableCell>
            <TableCell>{row.state}</TableCell>
            <TableCell>{row.zip}</TableCell>
            <TableCell>
              <a
                target="_blank"
                href={`https://www.google.com/maps/place/${row.lat},${row.lng}`}
              >
                Link
              </a>
            </TableCell>
            <TableCell>{row.email || "No email"}</TableCell>
            <TableCell>
              {row.registrationDate
                ? row.registrationDate.toDate().toLocaleDateString()
                : "No date"}
            </TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  </TableContainer>
);

const ActionButtons = ({ selected, error, loading, onApprove, onReject }) => (
  <>
    <LoadingButton
      disabled={!selected.length || !!error || !!loading}
      variant="contained"
      loading={loading === "approve"}
      onClick={onApprove}
      size="large"
      sx={{ display: "block", mb: 3 }}
    >
      Approve Selected Locations
    </LoadingButton>

    <LoadingButton
      disabled={!selected.length || !!error || !!loading}
      variant="contained"
      color="error"
      loading={loading === "reject"}
      onClick={onReject}
      size="large"
      sx={{ display: "block" }}
    >
      Reject Selected Locations
    </LoadingButton>
  </>
);

const Approval = () => {
  const { user, userData, subscription, userLoading } = useContext(AuthContext);
  const navigate = useNavigate();

  const [rows, setRows] = useState([]);
  const [selected, setSelected] = useState([]);
  const [submitted, setSubmitted] = useState();
  const [dataLoaded, setDataLoaded] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState();

  const sortedRows = useMemo(
    () =>
      [...rows].sort((a, b) => {
        if (!a.registrationDate) return 1;
        if (!b.registrationDate) return -1;
        return b.registrationDate.toDate() - a.registrationDate.toDate();
      }),
    [rows]
  );

  const handleSelectAllClick = useCallback(
    (event) => {
      setSelected(event.target.checked ? rows.map((n) => n.id) : []);
    },
    [rows]
  );

  const handleClick = useCallback(
    (event, id) => {
      const selectedIndex = selected.indexOf(id);
      let newSelected = [];

      if (selectedIndex === -1) {
        newSelected = [...selected, id];
      } else {
        newSelected = selected.filter((itemId) => itemId !== id);
      }
      setSelected(newSelected);
    },
    [selected]
  );

  // Fetch locations and their corresponding emails in one go
  useEffect(() => {
    const fetchData = async () => {
      const locationsSnapshot = await db
        .collection("locations")
        .where("pending", "==", true)
        .get();

      const locations = [];
      const userIds = new Set();

      locationsSnapshot.forEach((doc) => {
        const data = doc.data();
        if (data) {
          locations.push({
            ...data,
            id: doc.id,
          });
          if (data.users) {
            data.users.forEach((userId) => userIds.add(userId));
          }
        }
      });

      // Fetch all user emails and registration dates in one batch
      if (userIds.size > 0) {
        const usersSnapshot = await db
          .collection("users")
          .where("uid", "in", Array.from(userIds))
          .get();

        const userData = {};
        usersSnapshot.forEach((doc) => {
          const data = doc.data();
          userData[data.uid] = {
            email: data.email,
            registrationDate: data.registrationDate,
          };
        });

        // Attach emails and registration dates to locations
        locations.forEach((location) => {
          if (location.users && location.users.length > 0) {
            const firstUser = userData[location.users[0]];
            location.email = location.users
              .map((userId) => userData[userId]?.email)
              .join(", ");
            location.registrationDate = firstUser?.registrationDate || null;
          }
        });
      }

      setRows(locations);
      setDataLoaded(true);
    };

    fetchData();
  }, []);

  const getUserEmails = async (ids) => {
    let userIds = [];
    let userEmails = [];

    ids.forEach((id) => {
      const users = rows.find((row) => row.id === id).users;

      userIds.push(...users);
    });

    await db
      .collection("users")
      .where("uid", "in", userIds)
      .get()
      .then((querySnapshot) => {
        querySnapshot.forEach((doc) => {
          userEmails.push(doc.data().email);
        });
      })
      .catch((error) => {
        console.log("Error getting documents: ", error);
      });

    return userEmails;
  };

  const approveLocations = async () => {
    if (!selected.length) return;

    setLoading("approve");
    setSubmitted();

    let batch = db.batch();

    selected.forEach((id) => {
      batch.set(
        db.collection("locations").doc(id),
        { pending: false },
        { merge: true }
      );
    });

    const emails = await getUserEmails(selected);

    emails.forEach((email) => {
      batch.set(db.collection("emails").doc(), {
        to: [email],
        message: {
          subject: "Your HLTHDSK Listing Has Been Approved",
          html: `<p>Your HLTHDSK listing has been approved and is now visible for patients to see on the map.</p><p>You can update the current estimated waiting time for your clinic and more from the <a href="https://${window.location.host}/dashboard">HLTHDSK dashboard</a>.</p>`,
        },
      });
    });

    batch.commit().then(() => {
      const updatedRows = rows.filter((row) => !selected.includes(row.id));

      setRows(updatedRows);
      setSelected([]);

      setLoading(false);
      setSubmitted("The selected locations have been approved.");
    });
  };

  const rejectLocations = async () => {
    if (!selected.length) return;

    setLoading("reject");
    setSubmitted();

    let batch = db.batch();

    selected.forEach((id) => {
      batch.set(
        db.collection("locations").doc(id),
        { pending: "rejected" },
        { merge: true }
      );
    });

    const emails = await getUserEmails(selected);

    emails.forEach((email) => {
      batch.set(db.collection("emails").doc(), {
        to: [email],
        message: {
          subject: "Your HLTHDSK Listing Has Been Rejected",
          html: `<p>Your HLTHDSK listing has been rejected and will not be visible for patients to see on the map.</p><p>If you think this decision has been made in error, please contact us at support@hlthdsk.com.</p>`,
        },
      });
    });

    batch.commit().then(() => {
      const updatedRows = rows.filter((row) => !selected.includes(row.id));

      setRows(updatedRows);
      setSelected([]);

      setLoading(false);
      setSubmitted("The selected locations have been rejected.");
    });
  };

  // A cap of 30 locations to be emailed at one time due to a Firebase limitation
  // https://firebase.google.com/docs/firestore/query-data/queries#in_not-in_and_array-contains-any
  useEffect(() => {
    if (selected.length > 30) {
      setError("A maximum of 30 locations can be approved at once.");
    } else {
      if (error) setError();
    }
  }, [selected]);

  if (userData && !userData?.admin) navigate("/dashboard");

  if (userData?.admin)
    return (
      <Box sx={{ mb: 4 }}>
        <Box sx={{ mb: 4 }}>
          <Typography
            variant="h4"
            component="h2"
            sx={{ mt: { xs: 1, sm: 5 }, mb: 2 }}
          >
            Approval
          </Typography>

          <Typography
            variant="body"
            sx={{ display: "block", maxWidth: "40rem" }}
          >
            Approve Urgent Care locations and allow them to appear on the map.
          </Typography>

          {submitted ? (
            <Alert severity="success" sx={{ mt: 3, mb: 4 }}>
              {submitted}
            </Alert>
          ) : null}
        </Box>

        {rows.length > 0 ? (
          <Box>
            <ApprovalTable
              rows={sortedRows}
              selected={selected}
              onSelectAll={handleSelectAllClick}
              onSelectOne={handleClick}
            />
            <ActionButtons
              selected={selected}
              error={error}
              loading={loading}
              onApprove={approveLocations}
              onReject={rejectLocations}
            />
            {error && (
              <FormHelperText error sx={{ mt: 2 }}>
                {error}
              </FormHelperText>
            )}
          </Box>
        ) : dataLoaded ? (
          <Alert severity="info">You're all caught up!</Alert>
        ) : (
          <CircularProgress size={30} />
        )}
      </Box>
    );
};

export default Approval;
