import React, { useEffect, useState } from "react";
import firebase from "firebase/compat/app";
import "firebase/compat/functions";
import { db } from "services/firebase";
import { useStripe } from "@stripe/react-stripe-js";
import {
  Alert,
  AlertTitle,
  Box,
  Typography,
  Stack,
  CircularProgress,
  Link,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import DiscountCode from "./DiscountCode";
import { useAuth } from "hooks/useAuth";

const Pricing = ({ uid, role, subscription }) => {
  const stripe = useStripe();
  const { hasValidSubscription } = useAuth();
  const [loading, setLoading] = useState();
  const [codeOpen, setCodeOpen] = useState(false);
  const [trial, setTrial] = useState(false);
  const [prices, setPrices] = useState({});

  const getPlans = async () => {
    if (role) {
      try {
        const plansSnapshot = await db
          .collection("plans")
          .where("active", "==", true)
          .where("role", "==", role)
          .get();

        const pricePromises = plansSnapshot.docs.map(async (doc) => {
          const productData = doc.data();

          const pricesSnapshot = await db
            .collection("plans")
            .doc(doc.id)
            .collection("prices")
            .where("active", "==", true)
            .get();

          const priceList = {};

          pricesSnapshot.forEach((price) => {
            const priceData = price.data();
            console.log("Price data:", priceData); // Debug log

            const roundNumber =
              String(priceData.unit_amount).slice(-2) === "00";
            const formattedAmount = roundNumber
              ? priceData.unit_amount / 100
              : (priceData.unit_amount / 100).toFixed(2);

            const newPrice = {
              id: price.id,
              role: productData.role,
              price: `$${formattedAmount}`,
              interval: priceData.interval || "day",
              type: priceData.type || "recurring",
            };

            // Determine the key based on type and interval
            let key;
            if (priceData.type === "one_time") {
              key = "day";
            } else {
              key = priceData.interval;
            }

            console.log(`Setting price for key ${key}:`, newPrice); // Debug log
            priceList[key] = newPrice;
          });

          return priceList;
        });

        const allPriceLists = await Promise.all(pricePromises);
        const mergedPrices = allPriceLists.reduce(
          (acc, curr) => ({
            ...acc,
            ...curr,
          }),
          {}
        );

        console.log("Final prices object:", mergedPrices); // Debug log
        setPrices(mergedPrices);
      } catch (error) {
        console.error("Error fetching plans:", error);
      }
    }
  };

  useEffect(() => {
    getPlans();
  }, [role]);

  const sendToPortal = async () => {
    setLoading(true);

    const functionRef = firebase
      .app()
      .functions("us-central1")
      .httpsCallable("ext-firestore-stripe-payments-createPortalLink");
    const { data } = await functionRef({
      returnUrl: window.location.href,
    });
    window.location.assign(data.url);
  };

  const sendToCheckout = (plan, isDaily = false) => {
    setLoading(plan);

    let checkout;

    if (isDaily) {
      // For daily plans, create a one-time payment
      checkout = {
        price: plan,
        payment_method_collection: "always",
        mode: "payment", // Use payment mode instead of subscription
        metadata: {
          type: "daily_pass",
          userId: uid,
          expiresAt: new Date(Date.now() + 24 * 60 * 60 * 1000).toISOString(),
        },
        success_url: window.location.origin + "/dashboard",
        cancel_url: window.location.href,
      };
    } else {
      // Regular subscription checkout for monthly/yearly plans
      checkout =
        role === "patient"
          ? {
              price: plan,
              trial_period_days: trial ? 7 : 1,
              billing_address_collection: "auto",
              payment_method_collection: "if_required",
              mode: "subscription",
              success_url: window.location.origin + "/dashboard",
              cancel_url: window.location.href,
            }
          : {
              price: plan,
              mode: "subscription",
              success_url: window.location.origin + "/dashboard",
              cancel_url: window.location.href,
            };
    }

    db.collection("users")
      .doc(uid)
      .collection("checkout_sessions")
      .add(checkout)
      .then((docRef) => {
        docRef.onSnapshot((snap) => {
          const { error, sessionId } = snap.data();
          if (error) {
            alert(`An error occurred: ${error.message}`);
          }

          if (sessionId) {
            stripe.redirectToCheckout({ sessionId });
          }
        });
      });
  };

  if (hasValidSubscription === false) {
    return (
      <Box>
        <Typography variant="body">
          <p>Your subscription has expired.</p>
          <p>
            To continue using the Medical SuperIntelligence, please add your
            billing information below.
          </p>
          <LoadingButton
            loading={loading}
            variant="contained"
            disabled={loading}
            onClick={sendToPortal}
          >
            Subscribe Now
          </LoadingButton>
        </Typography>
      </Box>
    );
  }

  return Object.keys(prices).length > 0 ? (
    <>
      <Box>
        {role === "patient" ? (
          <>
            <Typography variant="body">
              <p>
                <strong>
                  For monthly and yearly plans, get a 24 hour free trial.
                </strong>{" "}
                No credit card required.
              </p>
            </Typography>
            <Box sx={{ mb: 2 }}>
              <Link
                underline="none"
                sx={{ pb: 2, cursor: "pointer" }}
                onClick={() => setCodeOpen(!codeOpen)}
              >
                Have a discount code?
              </Link>
              <DiscountCode show={codeOpen} setTrial={setTrial} />
              <Alert severity="info" sx={{ mt: 2, mb: 3 }}>
                <AlertTitle>Important Subscriber Information</AlertTitle>
                Thank you for considering a subscription with us! We want to
                ensure the best experience for all users, so please be aware
                that all subscriptions are subject to use restrictions. These
                guidelines are in place to help maintain the overall system's
                viability and ensure fair access for everyone. We appreciate
                your understanding and cooperation.
              </Alert>
            </Box>
          </>
        ) : null}
      </Box>

      <Stack
        direction={{ xs: "column", lg: "row" }}
        spacing={{ xs: 2, lg: 4 }}
        sx={{
          mt: { xs: 0, lg: 3 },
          mb: 4,
          width: "100%",
          alignItems: "stretch",
        }}
      >
        <Price
          title={"Monthly Plan"}
          price={prices.month}
          sendToCheckout={sendToCheckout}
          loading={loading}
          isDaily={false}
        />
        <Price
          title={"Yearly Plan"}
          price={prices.year}
          sendToCheckout={sendToCheckout}
          loading={loading}
          isDaily={false}
        />
        <Price
          title={"Day Pass"}
          description={"24-hour access. Does not auto-renew."}
          price={prices.day}
          sendToCheckout={sendToCheckout}
          loading={loading}
          isDaily={true}
        />
      </Stack>
    </>
  ) : (
    <Box style={{ display: "flex", justifyContent: "center" }}>
      <CircularProgress color="primary" size={30} />
    </Box>
  );
};

const Price = ({
  title,
  description,
  price,
  sendToCheckout,
  loading,
  isDaily,
}) => {
  if (price === undefined || !price.id || !price.price) {
    console.log(`Skipping render for ${title} due to invalid price data`);
    return null;
  }

  return (
    <Box
      key={price.id}
      sx={{
        boxShadow: 1,
        borderRadius: 2,
        p: { xs: 2, lg: 6 },
        pb: { xs: 3, lg: 6 },
        mb: { xs: 2, lg: 1 },
        textAlign: "center",
        width: "100%",
      }}
    >
      <Typography variant="h6" sx={{ mb: 1 }}>
        {title}
      </Typography>

      <Typography variant="body" sx={{ display: "block", mb: 2 }}>
        <strong>
          {price.price}/{price.interval}
        </strong>
        . {description ? description : "Cancel anytime."}
      </Typography>

      <LoadingButton
        loading={loading === price.id}
        disabled={!!loading}
        onClick={() => sendToCheckout(price.id, isDaily)}
        size={price.role === "patient" ? "small" : "large"}
        variant="contained"
      >
        {isDaily
          ? "Purchase Day Pass"
          : price.role === "patient"
          ? "Start 24 Hour Free Trial"
          : "Upgrade"}
      </LoadingButton>
    </Box>
  );
};

export default Pricing;
