import React, { useState, useEffect } from "react";
import firebaseApp, { db } from "services/firebase";
import {
  Alert,
  Box,
  Button,
  CircularProgress,
  Typography,
} from "@mui/material";
import { Link, useNavigate } from "react-router-dom";
import AccountVerifyEmail from "./account/AccountVerifyEmail";
import AccountResetPassword from "./account/AccountResetPassword";

const Wrapper = ({ children }) => (
  <Box sx={{ textAlign: "center", maxWidth: "sm", m: "auto" }}>{children}</Box>
);

export default () => {
  const [requestType, setRequestType] = useState();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);

  const [verified, setVerified] = useState(false);

  let navigate = useNavigate();

  const getRequestType = () => {
    const type = getParameter("mode");
    setRequestType(type);

    return type;
  };

  // Get parameters in the URL
  const getParameter = (parameter) => {
    const matches = new RegExp(`${parameter}=([^&#=]*)`).exec(
      window.location.search
    );
    if (matches) return matches[1];

    return undefined;
  };

  const handleVerifyEmail = () => {
    const code = getParameter("oobCode");

    if (!code) {
      setLoading(false);
      setError(true);
      return;
    }

    firebaseApp
      .auth()
      .applyActionCode(getParameter("oobCode"))
      .then(async (resp) => {
        try {
          setLoading(false);
          setVerified(true);

          // Get current user
          const user = firebaseApp.auth().currentUser;
          if (!user) {
            // If no user, redirect to login after showing message for 3 seconds
            setTimeout(() => {
              navigate("/login", {
                state: {
                  message:
                    "Please sign in again to complete your email verification.",
                },
              });
            }, 3000);
            return;
          }

          try {
            // Force reload user data to ensure we have the latest email
            await user.reload();
            // Force token refresh
            await user.getIdToken(true);

            // Small delay to allow token propagation and message to be read
            await new Promise((resolve) => setTimeout(resolve, 3000));

            // Navigate to dashboard
            navigate("/login", {
              state: {
                message:
                  "Your email has been verified. Please sign in with your new email address.",
              },
            });
          } catch (tokenError) {
            console.error("Auth refresh error:", tokenError);
            // If token refresh fails, show message for 3 seconds then redirect
            setTimeout(() => {
              navigate("/login", {
                state: {
                  message:
                    "Your session has expired. Please sign in again to continue.",
                },
              });
            }, 3000);
          }
        } catch (error) {
          console.error("Verification error:", error);
          setError(true);
        }
      })
      .catch((error) => {
        console.error("Action code error:", error);
        setLoading(false);
        setError(true);
      });
  };

  const handlePasswordReset = () => {
    const code = getParameter("oobCode");

    if (!code) {
      setLoading(false);
      setError(true);
      return;
    }

    firebaseApp
      .auth()
      .verifyPasswordResetCode(code)
      .then(() => {
        // Show the password reset form
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
        setError(true);
      });
  };

  const handleRecoverEmail = () => {
    const code = getParameter("oobCode");

    if (!code) {
      setLoading(false);
      setError(true);
      return;
    }

    firebaseApp
      .auth()
      .checkActionCode(code)
      .then((info) => {
        return firebaseApp
          .auth()
          .applyActionCode(code)
          .then(async () => {
            try {
              setLoading(false);
              setVerified(true);

              // Get current user
              const user = firebaseApp.auth().currentUser;
              if (!user) {
                // If no user, redirect to login after showing message for 3 seconds
                setTimeout(() => {
                  navigate("/login", {
                    state: {
                      message:
                        "Please sign in again to complete your email recovery.",
                    },
                  });
                }, 3000);
                return;
              }

              try {
                // Force reload user data
                await user.reload();
                // Force token refresh
                await user.getIdToken(true);

                // Small delay to allow token propagation and message to be read
                await new Promise((resolve) => setTimeout(resolve, 3000));

                // Navigate to dashboard
                navigate("/login", {
                  state: {
                    message:
                      "Your email has been recovered. Please sign in with your restored email address.",
                  },
                });
              } catch (tokenError) {
                console.error("Auth refresh error:", tokenError);
                // If token refresh fails, show message for 3 seconds then redirect
                setTimeout(() => {
                  navigate("/login", {
                    state: {
                      message:
                        "Your session has expired. Please sign in again to continue.",
                    },
                  });
                }, 3000);
              }
            } catch (error) {
              console.error("Recovery error:", error);
              setError(true);
            }
          });
      })
      .catch((error) => {
        console.error("Action code error:", error);
        setLoading(false);
        setError(true);
      });
  };

  useEffect(() => {
    const requestType = getRequestType();

    if (
      requestType === "verifyEmail" ||
      requestType === "verifyAndChangeEmail"
    ) {
      handleVerifyEmail();
    } else if (requestType === "resetPassword") {
      handlePasswordReset();
    } else if (requestType === "recoverEmail") {
      handleRecoverEmail();
    } else {
      navigate("/dashboard");
    }
  }, []);

  if (loading)
    return (
      <Wrapper>
        <CircularProgress sx={{ mt: 8 }} />
      </Wrapper>
    );

  console.log({ error });

  if (error) {
    let errorMessage;

    if (requestType === "verifyEmail" || requestType === "verifyAndChangeEmail")
      errorMessage =
        "Your request to verify your email has expired or the link has already been used. Please try verifying your email again.";

    if (requestType === "resetPassword")
      errorMessage =
        "Your request to reset your password has expired or the link has already been used. Please try again.";

    if (requestType === "recoverEmail")
      errorMessage =
        "Your request to recover your email has expired or the link has already been used. Please contact support if you need assistance.";

    return (
      <Wrapper>
        <Typography variant="h4" sx={{ mt: { xs: 4, sm: 10 }, mb: 2 }}>
          Your link has expired
        </Typography>

        <Typography variant="body">{errorMessage}</Typography>

        <Link to="/login">
          <Button>Return to Login</Button>
        </Link>
      </Wrapper>
    );
  }

  if (
    requestType === "verifyEmail" ||
    requestType === "verifyAndChangeEmail" ||
    requestType === "recoverEmail"
  )
    return (
      <Wrapper>
        <AccountVerifyEmail
          verified={verified}
          isEmailRecovery={requestType === "recoverEmail"}
        />
      </Wrapper>
    );

  if (requestType === "resetPassword")
    return (
      <Wrapper>
        <AccountResetPassword getParameter={getParameter} />
      </Wrapper>
    );
};
