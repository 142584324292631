import React from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import AdminTimeForm from "components/dashboard/AdminTimeForm";
import VirtualRegistrationSettings from "components/vaccine/queue/VirtualRegistrationSettings";
import TextSequence from "components/vaccine/TextSequence";
import WaitingRoom from "components/queue/WaitingRoom";
import Scheduler from "components/dashboard/Scheduler";
import Account from "components/dashboard/Account";
import Advertising from "components/dashboard/advertising/Advertising";
import UpgradePage from "pages/dashboard/UpgradePage";
import Approval from "components/dashboard/admin/Approval";
import { useFacility } from "hooks/useFacility";
import { useAuth } from "hooks/useAuth";

const DashboardRoutes = () => {
  const { data, setData } = useFacility();
  const { user, subscription } = useAuth();

  return (
    <Routes>
      <Route exact path="/" element={<AdminTimeForm data={data} />} />

      <Route
        path="/sequence"
        element={
          <div className="inner">
            <VirtualRegistrationSettings data={data} setData={setData} />
            <TextSequence data={data} setData={setData} />
          </div>
        }
      />

      <Route
        path="/queue"
        element={<WaitingRoom data={data} setData={setData} />}
      />

      <Route
        path="/schedule"
        element={<Scheduler data={data} setData={setData} />}
      />

      <Route
        path="/account"
        element={<Account uid={user.uid} subscription={subscription} />}
      />

      <Route path="/upgrade" element={<UpgradePage />} />

      <Route path="/advertising/*" element={<Advertising />} />
      <Route path="/admin/approval" element={<Approval />} />

      <Route path="*" element={<Navigate to="/dashboard" />} />
    </Routes>
  );
};

export default DashboardRoutes;
