import React, { useState, useEffect, useRef } from "react";
import firebaseApp, { db } from "services/firebase";
import firebase from "firebase/compat/app";

export const AuthContext = React.createContext();

export const AuthProvider = ({ children }) => {
  const [user, setUser] = useState();
  const [subscription, setSubscription] = useState();
  const [dailyPass, setDailyPass] = useState();
  const [userData, setUserData] = useState();
  const [loading, setLoading] = useState(true);
  const unsubRef = useRef();
  const dailyPassUnsubRef = useRef();

  const getData = (uid) => {
    return db
      .collection("users")
      .doc(uid)
      .onSnapshot((user) => {
        setUserData(user.data());
      });
  };

  const checkDailyPass = (uid) => {
    return db
      .collection("users")
      .doc(uid)
      .collection("daily_passes")
      .where("expiresAt", ">", new Date().toISOString())
      .where("status", "==", "active")
      .onSnapshot((snapshot) => {
        const activePass = snapshot.docs[0]?.data();
        setDailyPass(
          activePass
            ? {
                expiresAt: activePass.expiresAt,
                status: activePass.status,
              }
            : null
        );
      });
  };

  useEffect(() => {
    let authListener = firebaseApp.auth().onAuthStateChanged(async (user) => {
      setUser(user);

      if (user) {
        await firebaseApp.auth().currentUser?.getIdToken(true);
        const decodedToken = await firebaseApp
          .auth()
          .currentUser?.getIdTokenResult();

        let role = decodedToken?.claims?.stripeRole;
        setSubscription(role);
        unsubRef.current = getData(user.uid);
        dailyPassUnsubRef.current = checkDailyPass(user.uid);
      } else {
        setSubscription(null);
        setUserData(null);
        setDailyPass(null);
      }
      setLoading(false);
    });

    return () => {
      authListener();
      if (unsubRef.current) unsubRef.current();
      if (dailyPassUnsubRef.current) dailyPassUnsubRef.current();
    };
  }, []);

  const handleLogout = async () => {
    if (unsubRef.current) {
      unsubRef.current();
      unsubRef.current = null;
    }
    if (dailyPassUnsubRef.current) {
      dailyPassUnsubRef.current();
      dailyPassUnsubRef.current = null;
    }
    await firebase.auth().signOut();
  };

  return (
    <AuthContext.Provider
      value={{
        user,
        subscription,
        dailyPass,
        userData,
        userLoading: loading,
        logout: handleLogout,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};
