import React, { useState, useContext, useEffect } from "react";
import LinkedInTag from "react-linkedin-insight";
import { useForm } from "react-hook-form";

import { AuthContext } from "context/Auth";
// import { ZipContext } from "context/Zip";

import RegistrationForm from "components/auth/professionalRegister/RegistrationForm";
import NavigationLinks from "components/auth/layout/NavigationLinks";
import { createProfessionalUser } from "components/auth/professionalRegister/professionalHelpers";
import { useNavigate } from "react-router-dom";

const ProfessionalRegisterForm = () => {
  const [loading, setLoading] = useState(false);
  const [firebaseErrors, setFirebaseErrors] = useState("");
  const navigate = useNavigate();

  // const { branding } = useContext(ZipContext);
  const { user } = useContext(AuthContext);

  const {
    handleSubmit,
    formState: { errors },
    control,
  } = useForm({
    mode: "onBlur",
    shouldUnregister: false,
  });

  useEffect(() => {
    LinkedInTag.init(1439474);
  }, []);

  const onSubmit = async (formData) => {
    setLoading(true);

    try {
      await createProfessionalUser(formData.email, formData.password, formData);
      navigate("/dashboard");
    } catch (error) {
      setLoading(false);
      setFirebaseErrors(error.message);
      if (user) await user.delete();
    }
  };

  return (
    <>
      <RegistrationForm
        control={control}
        errors={errors}
        loading={loading}
        firebaseErrors={firebaseErrors}
        handleSubmit={handleSubmit}
        onSubmit={onSubmit}
        // branding={branding}
      />
      <NavigationLinks />
    </>
  );
};

export default ProfessionalRegisterForm;
