import React from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import PatientBilling from "components/chatbot/PatientBilling";
import { useAuth } from "hooks/useAuth";
import { useFacility } from "hooks/useFacility";
import DiscussSymptomsPage from "pages/dashboard/individual/DiscussSymptomsPage";
import UpgradePage from "pages/dashboard/UpgradePage";
import Approval from "components/dashboard/admin/Approval";

const PatientRoutes = () => {
  const { userData } = useAuth();
  const { data } = useFacility();

  return (
    <Routes>
      {/* <Route
        exact
        path="/"
        element={
          <div>
            <PatientDashboard userData={userData} branding={data.branding} />
          </div>
        }
      /> */}
      <Route
        path="/"
        element={
          <div style={{ height: "100dvh" }}>
            <DiscussSymptomsPage />
          </div>
        }
      />
      <Route path="/account" element={<PatientBilling userData={userData} />} />

      <Route path="/upgrade" element={<UpgradePage />} />

      <Route path="/admin/approval" element={<Approval />} />

      {/* Catch-all route to dashboard */}
      {/* <Route path="*" element={<Navigate to="/dashboard" />} /> */}
    </Routes>
  );
};

export default PatientRoutes;
