import React, { useRef } from "react";
import DashboardNav from "components/dashboard/layout/DashboardNav";
import BetaDisclaimer from "components/dashboard/BetaDisclaimer";
import { useFacility } from "hooks/useFacility";
import FacilityAlerts from "components/dashboard/FacilityAlerts";
import { useAuth } from "hooks/useAuth";

const DashboardLayout = ({ children }) => {
  const boxRef = useRef(null);
  const { data } = useFacility();
  const { userData, isVerified } = useAuth();

  // Redirect if vaccines-only facility
  const only_vaccines = data?.type === 3;
  if (
    only_vaccines &&
    window.location.pathname.replaceAll("/", "") === "dashboard"
  ) {
    window.location = "/dashboard/vaccine/queue";
    return null;
  }

  return (
    <DashboardNav boxRef={boxRef}>
      {userData?.role === "facility" && isVerified && (
        <FacilityAlerts data={data} />
      )}
      {children}
      <BetaDisclaimer />
    </DashboardNav>
  );
};

export default DashboardLayout;
