import { useState, useEffect, useContext, useMemo } from "react";
import { AuthContext } from "context/Auth";
import firebaseApp from "services/firebase";

export const useAuth = () => {
  const context = useContext(AuthContext);
  const [loading, setLoading] = useState(false);
  const [submitted, setSubmitted] = useState(false);
  const [dbError, setDbError] = useState(null);

  if (context === undefined) {
    throw new Error("useAuth must be used within an AuthProvider");
  }

  const { user, subscription, dailyPass, userData, userLoading, logout } =
    context;

  const isAuthenticated = useMemo(() => {
    return user !== null && !userLoading;
  }, [user, userLoading]);

  const isVerified = useMemo(() => {
    return isAuthenticated && user?.emailVerified;
  }, [isAuthenticated, user]);

  const hasRole = useMemo(() => {
    return isVerified && userData?.role;
  }, [isVerified, userData]);

  const hasValidSubscription = useMemo(() => {
    return (
      hasRole &&
      (subscription ||
        userData?.role === "facility" ||
        (dailyPass &&
          dailyPass.status === "active" &&
          new Date(dailyPass.expiresAt) > new Date()))
    );
  }, [hasRole, subscription, userData, dailyPass]);

  const hasActiveDailyPass = useMemo(() => {
    return (
      dailyPass &&
      dailyPass.status === "active" &&
      new Date(dailyPass.expiresAt) > new Date()
    );
  }, [dailyPass]);

  const activeSubscriptionRole = useMemo(() => {
    if (!isVerified) return undefined;

    // Check for daily pass first
    if (hasActiveDailyPass) {
      return userData?.role;
    }

    // Then check for stripe subscription
    if (subscription) {
      return userData?.role;
    }

    // No valid subscription found
    return undefined;
  }, [isVerified, hasActiveDailyPass, subscription, userData?.role]);

  const canUseFreeTrial = useMemo(() => {
    return subscription === "facility" && !userData?.hasUsedFreeTrial;
  }, [subscription, userData]);

  const resendEmail = async () => {
    setLoading(true);
    setDbError(null);

    try {
      await user.sendEmailVerification();
      setSubmitted(true);
    } catch (error) {
      setDbError(error.message);
      console.error("Email verification error:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (user) {
      setDbError(null);
      setSubmitted(false);
    }
  }, [user]);

  return {
    // User state
    user,
    userData,
    subscription,
    dailyPass,
    userLoading,

    // Verification state
    loading,
    submitted,
    dbError,

    // Computed state
    isAuthenticated,
    isVerified,
    hasRole,
    hasValidSubscription,
    hasActiveDailyPass,
    activeSubscriptionRole,
    canUseFreeTrial,

    // Methods
    resendEmail,
    logout,
  };
};
