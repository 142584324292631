import React from "react";
import { useNavigate } from "react-router-dom";
import { useAuth } from "hooks/useAuth";
import { Box, Typography } from "@mui/material";
import UpgradeFeatures from "components/dashboard/UpgradeFeatures";
import Pricing from "components/dashboard/Pricing";

const UpgradePage = () => {
  const { userData, subscription } = useAuth();
  const navigate = useNavigate();

  if (subscription) navigate("/dashboard");

  const getUpgradeDescription = (role) => {
    switch (role) {
      case "patient":
        return "get unlimited access to Medical SuperIntelligence, your personal health assistant.";
      case "professional":
        return "get unlimited access to Medical SuperIntelligence for healthcare professionals.";
      default:
        return "supercharge your facility.";
    }
  };

  return (
    <Box>
      <Typography
        variant="h4"
        component="h2"
        sx={{ mt: { xs: 1, sm: 5 }, mb: 2 }}
      >
        Upgrade
      </Typography>

      <Typography variant="body" sx={{ display: "block", mt: 1, mb: 2 }}>
        Upgrade to HLTHDSK Plus to access all our features and{" "}
        {getUpgradeDescription(userData?.role)}
      </Typography>

      <UpgradeFeatures role={userData?.role} />

      <Pricing
        uid={userData?.uid}
        role={userData?.role}
        subscription={subscription}
      />
    </Box>
  );
};

export default UpgradePage;
